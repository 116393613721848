import React from "react";

import GatsbyImage from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroGradient from "../components/General/HeroGradient";
import Details from "../components/Contact/Details";
import CTA from "../components/General/CTA";
import Footer from "../components/Context/Footer";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const ContactUs = ({ data: staticData }) => {
    const data = usePreviewData(staticData);
    const {
        name,
        tagline,
        office,
        contact_us,
        image,
        heading_promo,
        teaser_promo,
        button_cta,
        link_promo,
        meta_description,
        share_image
    } = data.prismicContactU.data;
    const theme = "light";
    return (
        <Layout theme={theme}>
            <SEO title={name.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroGradient
                title={name.text}
                subtitle={tagline.html}
                theme={theme}
            />
            <Details
                contentOffice={office.html}
                contentContact={contact_us.html}
                image={<GatsbyImage fluid={image.fluid} alt={image.alt} />}
                theme={theme}
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser_promo.html}
                buttonText={button_cta}
                buttonUrl={link_promo.url}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    {
        prismicContactU {
            data {
                name {
                    text
                }
                tagline {
                    html
                }
                office {
                    html
                }
                contact_us {
                    html
                }
                image {
                    alt
                    fluid(maxWidth: 1400) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                heading_promo {
                    text
                }
                teaser_promo {
                    html
                }
                button_cta
                link_promo {
                    url
                    target
                    link_type
                }
                meta_description
                share_image {
                    url
                }
            }
        }
    }
`;

export default ContactUs;
